import React, { useState } from "react";
import cx from "classnames";

import { IonButton, IonIcon, IonNote, IonPopover } from "@ionic/react";
import {
  alertCircleOutline,
  checkmarkCircleOutline,
  closeCircleOutline,
  cloudUploadOutline,
  informationCircleOutline,
} from "ionicons/icons";

import { useFileUploadService } from "utils/hooks/learner-identity";
import { NxuAlert } from "@nexford/nexford-ui-component-library";

import "./education-transcript-upload.scss";

export interface EducationTranscriptUploadProps {
  setUploadResponse: (uploadResponse: { originalFileName: string; uploadedFileName: string } | undefined) => void;
  inProgress?: boolean;
  existingOriginalFileName?: string;
}

/**
 * Upload handler for transcripts
 */
const EducationTranscriptUpload = ({
  setUploadResponse,
  inProgress,
  existingOriginalFileName,
}: EducationTranscriptUploadProps) => {
  const [currentFile, setCurrentFile] = useState<File>();
  const [currentFileName, setCurrentFileName] = useState<string | undefined>(existingOriginalFileName);
  const [currentFileError, setCurrentFileError] = useState<string>();

  const [fileUploadSubmitting, setFileUploadSubmitting] = useState(false);
  const [fileUploadSuccess, setFileUploadSuccess] = useState(!!existingOriginalFileName);
  const [uploadError, setUploadError] = useState<string>();

  const fileUploadService = useFileUploadService();

  const reset = () => {
    setCurrentFile(undefined);
    setCurrentFileName(undefined);
    setCurrentFileError(undefined);
    setFileUploadSuccess(false);
    setUploadError(undefined);
    setUploadResponse(undefined);
    const inputField = document.getElementById(`transcript-upload-field`);
    // @ts-ignore
    if (inputField) inputField.value = null;
  };

  const getFileExtension = (file: File) => file.name.substring(file.name.lastIndexOf(".") + 1);

  const allowedFileType = (type: string): boolean =>
    type.endsWith("png") ||
    type.endsWith("png") ||
    type.endsWith("jpeg") ||
    type.endsWith("jpg") ||
    type.endsWith("pdf") ||
    type.endsWith("doc") ||
    type.endsWith("docx") ||
    type.endsWith("document");

  // Validate file types & size for photo upload
  const isTranscriptValid = (file: File) => {
    if (!allowedFileType(file.type)) {
      setCurrentFileError("You are uploading the wrong file type. Only .pdf, .doc, .jpg or .png files are accepted.");
      return false;
    }

    if (file.size > 10485760) {
      setCurrentFileError("File size is larger than allowed size. The file must be less than 10 MB");
      return false;
    }
    setCurrentFileError(undefined);
    return true;
  };

  // Upload the file to the api
  const submitForm = async (file: File) => {
    if (inProgress) return;
    if (currentFile) reset();
    setCurrentFileName(file.name);
    setCurrentFile(file);

    const isFileValid = file && isTranscriptValid(file);

    if (isFileValid) {
      setFileUploadSubmitting(true);
      const uploadFileType = getFileExtension(file);

      fileUploadService.mutate(
        {
          payload: { file },
          fileExtension: uploadFileType,
        },
        {
          onSuccess(response) {
            setFileUploadSubmitting(false);
            setFileUploadSuccess(true);
            setUploadResponse({
              originalFileName: file.name,
              uploadedFileName: response.tempFileId,
            });
          },
          onError(e) {
            setUploadResponse(undefined);
            setFileUploadSubmitting(false);
            setUploadError(e.message);
          },
        },
      );
    }
  };

  // Trigger the file upload UI to open
  const openFileUpload = (e: React.MouseEvent) => {
    e.preventDefault();
    const inputField = document.getElementById(`transcript-upload-field`);
    inputField?.click();
  };

  return (
    <div data-testid={`upload-document-form-transcript`} className="transcript-document__form">
      {uploadError && <NxuAlert fullWidth message={uploadError} />}
      <div
        className={cx(
          "transcript-document__field-wrapper",
          !!currentFileError && "transcript-document__field-wrapper--error",
        )}
      >
        <input
          data-testid={`transcript-upload-field`}
          id={`transcript-upload-field`}
          type="file"
          hidden
          accept="image/png, image/jpeg, image/jpg"
          onChange={(e) => {
            const file = e.target.files && e.target.files[0];
            if (file) submitForm(file);
          }}
        />
        <div className="transcript-document__field-row">
          <div className="transcript-document__field-row__cta">
            <IonButton
              id={`transcript-tooltip-trigger`}
              shape="round"
              color="dark"
              fill="clear"
              aria-label={`Open tooltip for transcript upload`}
            >
              <IonIcon slot="icon-only" icon={informationCircleOutline}></IonIcon>
            </IonButton>
          </div>

          <button
            disabled={fileUploadSubmitting || inProgress}
            className={cx(
              "transcript-document__upload-btn",
              !!currentFileName && "transcript-document__upload-btn--complete",
            )}
            onClick={(e) => openFileUpload(e)}
          >
            <span>{currentFileName || "Optional: diploma or transcript"}</span>
            {!uploadError && !currentFileError && !fileUploadSuccess && (
              <IonIcon slot="icon-only" icon={cloudUploadOutline}></IonIcon>
            )}
            {fileUploadSuccess && <IonIcon color="success" slot="icon-only" icon={checkmarkCircleOutline}></IonIcon>}
            {(!!uploadError || !!currentFileError) && (
              <IonIcon color="danger" slot="icon-only" icon={alertCircleOutline}></IonIcon>
            )}
          </button>

          <div className="transcript-document__field-row__cta">
            <IonButton
              shape="round"
              color="dark"
              fill="clear"
              aria-label="Clear the uploaded file for transcript or diploma"
              disabled={fileUploadSubmitting || inProgress || !currentFileName}
              onClick={() => {
                reset();
              }}
            >
              <IonIcon slot="icon-only" icon={closeCircleOutline}></IonIcon>
            </IonButton>
          </div>
        </div>
        {!!currentFileError && <IonNote color="danger">{currentFileError}</IonNote>}
      </div>
      <IonPopover
        className="upload-field-tooltip"
        trigger="transcript-tooltip-trigger"
        triggerAction="click"
        side="bottom"
        alignment="start"
      >
        <p>Upload your grades transcript or diploma for your previous education, if available.</p>
      </IonPopover>
    </div>
  );
};

export default EducationTranscriptUpload;
