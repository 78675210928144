// Payment Provider Options
export enum PPO {
  FLYWIRE = "flywire",
  FLUTTERWAVE = "flutterwave",
  STRIPE = "stripe",
  PAYPAL = "paypal",
  TRANSFER = "transfer",
  NONE = "none",
}

export type ProviderTypes =
  | typeof PPO.FLYWIRE
  | typeof PPO.FLUTTERWAVE
  | typeof PPO.STRIPE
  | typeof PPO.PAYPAL
  | typeof PPO.TRANSFER
  | typeof PPO.NONE;

export enum PaymentsTypeOptions {
  ApplicationFee = "ApplicationFee",
  TuitionFee = "TuitionFee",
}

export type PaymentTypes = PaymentsTypeOptions.ApplicationFee | PaymentsTypeOptions.TuitionFee;

export enum PaymentPollingStatus {
  INITIATED = "Initiated",
  SUBMITTED = "Submitted",
  REQUIRES_AUTH = "RequiresAuth",
  CANCELED = "Canceled",
  FAILED = "Failed",
  PAID = "Paid",
}

export enum PaymentPollingSubStatusOptions {
  GENERIC_DECLINE = "GenericDecline",
  DECLINED_TRANSACTION = "DeclinedTransaction",
  STOLEN_CARD = "StolenCard",
  SECURE_DECLINED = "SecureDeclined",
  FAILED_AUTHENTICATION = "FailedSecureAuthentication",
  FRAUDULENT_CARD = "FraudulentCard",
  VELOCITY_EXCEEDED = "VelocityExceeded",
  INSUFFICIENT_FUNDS = "InsufficientFunds",
  EXPIRED_CARD = "ExpiredCard",
  INCORRECT_CVC = "IncorrectCvc",
  PROCESSING_ERROR = "ProcessingError",
  INCORRECT_PIN = "IncorrectPin",
  PAYMENT_CANCELLED = "PaymentCancelled",
  PAYMENT_REFUSED = "PaymentRefused",
  UNKNOWN_ERROR = "UnkownError",
}

export type PaymentPollingSubStatus = keyof typeof PaymentPollingSubStatusOptions;
// Currency provider object received from the API
export interface CurrencyProvider {
  currencyCode: string;
  provider: ProviderTypes;
  amountUSD: number;
  amount: number;
  exchangeRate: number;
}

//
export interface ProviderItem {
  provider: ProviderTypes;
  providerAmount?: number;
  currencyCode?: string;
  callbackId?: string;
}

export interface StripeConfig {
  PublishableKey: string;
}

export interface FlywireConfig {
  amount: number;
  callbackUrl: string;
  env: string;
  recipientCode: string;
}

export interface FlywireCallback {
  Reference: string;
  Status: "success" | "pending" | "error";
  Amount: number;
  PaymentMethod: "online" | "bank_transfer";
  Nonce: string;
  Sig: string;
}

export interface FlutterwaveConfig {
  publicKey: string;
  subaccountId: string;
  subaccountName: string;
}

export interface PaymentProviders {
  currencies: Array<CurrencyProvider>;
  callbackId: string;
  flywireConfig?: any;
  flutterWaveConfig?: FlutterwaveConfig;
}

export interface PaymentEstimate {
  Discount: number;
  StartDate: string;
  Subtotal: number;
  Total: number;
}

// Applicant/Learner details used on Flutterwave payment
export interface FlutterwavePayee {
  learnerId: string;
  email: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
}

// Response object from Flutterwave payment
export interface FlutterwaveCallback {
  status: string;
  transaction_id: number;
  tx_ref: string;
  amount: number;
  currency: string;
}

export interface CardholderDetails {
  FirstName: string;
  LastName: string;
  Email: string;
  Street: string;
  City: string;
  PostalCode: string;
}

export interface PaymentFormInputs {
  FirstName: string;
  LastName: string;
  Email: string;
  Street: string;
  City: string;
  PostalCode: string;
}

// Stripe payment intent to be sent through to Nexford API
export interface StartPaymentStripeDetails {
  paymentMethodId?: string;
}

export interface AgreementDocumentPayload {
  email: string;
  officialName: string;
  city: string;
  country: string;
  startDate: string;
  paymentFrequency: number;
  subtotal: number;
  total: number;
  couponCode: string | null;
}

export interface AgreementDocumentResponse {
  AgreementFile: string;
  AgreementHtmlContent: string;
}

export interface AgreementAcceptancePayload {
  officialName: string;
  startDate: string;
}

export interface AgreementAcceptanceResponse {
  Catalog: string;
  CatalogAggendum: string;
  CatalogVersion: string;
  LearnerSignedAgreement: string;
  ProgramEndDate: string;
}

export interface StartPaymentRegistrationPayload {
  paymentReference: string;
  paymentType: PaymentTypes;
  provider: ProviderTypes;
  productType: string;
  productCode: string;
  productLevel: string;
  countryCode: string;
  paymentFrequency: number;
  startMonth: number;
  couponCode: string | null;
  amount: number;
  startPaymentStripeDetails?: StartPaymentStripeDetails;
}

export interface SubmitPaymentPayload {
  provider: ProviderTypes;
  paymentReference: string;
  paymentType: PaymentTypes;
}

export interface AuthorizePaymentPayload {
  paymentReference: string;
  paymentType: PaymentTypes;
  provider: ProviderTypes;
  externalReference: string;
  countryCode: string;
  paymentFrequency: number;
  startMonth: number;
  amount: number;
  couponCode: string | null;
}

export interface StartPaymentRegistrationResponse {
  paymentIntentClientSecret: string;
  provider: string;
  result: string;
  subscriptionId: string;
  countryCode: string;
}

export interface SubmitPaymentResponse {
  callbackId: string;
  provider: string;
  status: PaymentPollingStatus;
}

export interface PaymentPollingResponse {
  provider: ProviderTypes;
  paymentType: string;
  status: PaymentPollingStatus;
  substatus?: PaymentPollingSubStatus;
  paymentIntentResponse?: {
    paymentIntentId: string;
    clientSecret: string;
  };
}
export interface PaymentStatusResponse {
  ExternalReference: string;
  PaymentReference: string;
  paymentType: string;
  provider: ProviderTypes;
  status: PaymentPollingStatus;
  substatus?: PaymentPollingSubStatus;
}

export interface TuitionFeeError {
  ErrorCode: "InvalidCouponCode" | "InvalidPaymentDetails" | "ExpectedAmountMismatch" | "NoValidStartDateOptions";
  Detail?: string;
}
