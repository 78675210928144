import React, { useState } from "react";
import { IonButton, IonIcon, IonSpinner } from "@ionic/react";
import { searchOutline, layers, time, information, library } from "ionicons/icons";

import CardPanel from "components/atom/card-panel";
import ProductDetailsModal from "components/molecule/product-details-modal";
import PriceNotificationBlock from "components/molecule/price-notification-block";
import { useFullProgramDetails } from "utils/hooks/admissions";
import ProductsListModal from "components/molecule/products-list-modal";

import "./application-title-panel.scss";

export interface ApplicationTitlePanelProps {
  productType: string;
  productCode: string;
  productProvider: string;
  friendlyName: string;
  hideCatalogLink?: boolean;
}

/**
 * Program title block with link to leave application for catalogue
 */
const ApplicationTitlePanel = (props: ApplicationTitlePanelProps) => {
  const { productType, productCode, productProvider, friendlyName, hideCatalogLink } = props;

  const [isProductDetailsModalOpen, setIsProductDetailsModalOpen] = useState(false);
  const [isChangeProductModalOpen, setIsChangeProductModalOpen] = useState(false);

  const {
    data: programDetails,
    isLoading: programDetailsLoading,
    isError: programDetailsError,
  } = useFullProgramDetails(productType?.toUpperCase() === "COURSE", productCode);

  const renderFullDetails = productType?.toUpperCase() === "COURSE";

  const closeProductDetailsModal = () => {
    setIsProductDetailsModalOpen(false);
  };

  const closeProductsListModal = () => {
    setIsChangeProductModalOpen(false);
  };

  return (
    <>
      <CardPanel className="application-title-panel">
        <div>
          <div className="application-title-panel__row application-title-panel__row--title">
            <div>
              <h1>
                {productProvider === "Coursera" ? "Professional Certificate" : productType}
                {`: ${friendlyName}`}
              </h1>
            </div>
            {!hideCatalogLink && (
              <IonButton
                id="application-leave"
                shape="round"
                size="small"
                aria-label="Change program"
                type="button"
                onClick={() => setIsChangeProductModalOpen(true)}
              >
                <IonIcon slot="icon-only" icon={searchOutline}></IonIcon>
              </IonButton>
            )}
          </div>
          {renderFullDetails && (
            <div className="application-title-panel__row application-title-panel__row--extra">
              <div className="application-title-panel__row__content">
                <div>
                  <IonIcon icon={library}></IonIcon>
                  <span aria-label={`Program Code: ${productCode}`}>{productCode}</span>
                </div>
                {programDetails?.degrees?.length && (
                  <div>
                    <IonIcon icon={layers}></IonIcon>
                    <span>Stacks into degree</span>
                  </div>
                )}
                <div>
                  <IonIcon icon={time}></IonIcon>
                  <span>1-2 months</span>
                </div>
              </div>
              {!programDetailsError && (
                <>
                  {programDetailsLoading && (
                    <div
                      data-testid="application-title-panel__row__spinner"
                      className="application-title-panel__row__spinner"
                    >
                      <IonSpinner />
                    </div>
                  )}
                  {!programDetailsLoading && programDetails && (
                    <IonButton
                      shape="round"
                      size="small"
                      aria-label="Open program details"
                      type="button"
                      fill="outline"
                      onClick={() => setIsProductDetailsModalOpen(true)}
                    >
                      <IonIcon slot="icon-only" icon={information}></IonIcon>
                    </IonButton>
                  )}
                </>
              )}
            </div>
          )}
        </div>

        <ProductDetailsModal
          productDetails={programDetails}
          productName={friendlyName}
          isOpen={isProductDetailsModalOpen}
          closeModal={closeProductDetailsModal}
        />

        <ProductsListModal isOpen={isChangeProductModalOpen} closeModal={closeProductsListModal} />
      </CardPanel>
      <PriceNotificationBlock />
    </>
  );
};

export default ApplicationTitlePanel;
