import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { LocalRoutes } from "constants/routes";
import { StorageKeys } from "constants/storage-keys";
import { useRegistrationContext } from "utils/context/registration";
import { SubmitHandler } from "react-hook-form";
import { useEducationProfileInfo, useSubmitEducationInfo } from "utils/hooks/learner-profile";
import { RequirementType } from "types/registrations";

import { NxuComponentLoading } from "@nexford/nexford-ui-component-library";
import CardPanel from "components/atom/card-panel";
import EducationForm from "components/molecule/education-form";

import "./education-page.scss";
import { learningPathProducts } from "../../../constants/products";

/**
 * Education page
 */
const EducationPage = () => {
  const { registrationData, CompleteRequirements, setProcessing, token } = useRegistrationContext();

  const [screeningInProgress, setScreeningInProgress] = useState(false);
  const [hasLearningPathOption] = useState(learningPathProducts.includes(registrationData?.productCode || ""));
  const navigate = useNavigate();

  const { data: educationProfileApiData, isFetching: educationProfileDataFetching } = useEducationProfileInfo(
    token || "",
  );

  const { mutateAsync } = useSubmitEducationInfo((resp) => {
    if (hasLearningPathOption) {
      // When product is MBA, the navigation on complete registration will be disabled and a redirect to the MBA learning path will be called
      setScreeningInProgress(false);
      setProcessing(false);

      if (
        (resp.HighestEducationLevel === "Bachelor's Degree" ||
          resp.HighestEducationLevel === "Master's Degree or Higher") &&
        ((registrationData?.productCode === "MBA" && resp.IsBusinessRelated !== null && !resp.IsBusinessRelated) ||
          registrationData?.productCode !== "MBA") &&
        resp.InstitutionName &&
        resp.Accredited
      ) {
        sessionStorage.setItem(StorageKeys.FOUNDATION_COURSES_CANDIDATE, "true");
        navigate({
          pathname: LocalRoutes.APPLICATION_DEGREE_LEARNING_PATH,
          search: registrationData
            ? `type=${registrationData.productType}&product=${registrationData.productCode}`
            : "",
        });
      } else {
        sessionStorage.removeItem(StorageKeys.FOUNDATION_COURSES_CANDIDATE);
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (registrationData?.productCode === "MBA" && !timezone.startsWith("Africa/")) {
          // MBA applicants outside of Africa will still have multiple learning path options
          navigate({
            pathname: LocalRoutes.APPLICATION_DEGREE_LEARNING_PATH,
            search: registrationData
              ? `type=${registrationData.productType}&product=${registrationData.productCode}`
              : "",
          });
        } else {
          navigate({
            pathname: LocalRoutes.APPLICATION,
            search: registrationData
              ? `type=${registrationData.productType}&product=${registrationData.productCode}`
              : "",
          });
        }
      }
    }
  });

  const handleEducationPageSubmit: SubmitHandler<any> = async (formValues) => {
    setScreeningInProgress(true);
    // if the value is "Unsure" or other - set it to null
    const isBusinessRelated = (): boolean | null => {
      switch (formValues.BusinessRelated) {
        case "Yes":
          return true;
        case "No":
          return false;
        default:
          return null;
      }
    };

    CompleteRequirements(
      () =>
        mutateAsync({
          HighestEducationLevel: formValues.EducationLevelOther || formValues.EducationLevel,
          GraduationYear: formValues.GraduationYear ? Number(formValues.GraduationYear) : null,
          InstitutionName: formValues.InstitutionName || formValues.UnaccreditedInstitutionName,
          Accredited: !!formValues.InstitutionName,
          InstitutionLanguage: "",
          InstitutionCountry: "",
          DegreeOrCertificateName: formValues.DegreeName,
          IsBusinessRelated: isBusinessRelated(),
          UnofficialTranscriptFileId: formValues.UnofficialTranscriptFileId,
          UnofficialTranscriptFileName: formValues.UnofficialTranscriptFileName,
        }),
      [RequirementType.EducationInfo, RequirementType.UnofficialTranscript],
      hasLearningPathOption,
      hasLearningPathOption,
    );
  };

  if (educationProfileDataFetching) {
    return <NxuComponentLoading />;
  }

  return (
    <CardPanel className="application-page__screening-panel" testId="education-page">
      <p className="application-page__screening-panel-title">To start, we need to ask about your previous education</p>

      <EducationForm
        submitEventLabel="Continue"
        submitEventInProgressLabel="Screening in progress"
        submitEventInProgress={screeningInProgress}
        parentFormHandler={handleEducationPageSubmit}
        existingApplicantInfo={educationProfileApiData}
        productCode={registrationData?.productCode || ""}
      />
    </CardPanel>
  );
};

export default EducationPage;
